import { removeFromLocalStorage, restoreFromLocalStorage, saveToLocalStorage } from "./localstorage";
import { tryJSONParse } from '../utils/json';

export const DEFAULT_DOCUMENT_ID = "velocity-emulator-current";
export const DEFAULT_PROJECT_NAME = "default";

const projectKey = (name: string) => `/projects/${name}`;
const docKeyInProject = (id: string, projId: string) => `/projects/${projId}/${id}`;

export type EmulatorDocument = {
  id: string,
  created?: string,
  updated?: string,
  name: string,
  template: string,
  context: string
};

export type Project = {
  name: string,
  activeDocumentId: string,
  readOnly?: boolean,
  documents: Record<string, EmulatorDocument>
};

const { v4: uuid } = require('uuid');

// if (typeof (window as any).crypto === 'undefined')
//   window.crypto = require('crypto');

// if (!('randomUUID' in crypto))
//   // https://stackoverflow.com/a/2117523/2800218
//   // LICENSE: https://creativecommons.org/licenses/by-sa/4.0/legalcode
//   crypto.randomUUID = function randomUUID() {
//     return (
//       //@ts-ignore
//       [1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,
//       //@ts-ignore
//       c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
//     );
//   };


export const defaultNewDoc = (id?: string) => ({
  id: id || uuid(), //crypto.randomUUID(),
  created: new Date().toISOString(),
  updated: new Date().toISOString(),
  name: "",
  template: "",
  context: ""
});

export const defaultNewProject = (name: string = "default") => {
  if(name !== "default")
    name = "default"; // until the day

  return {
    name,
    documents: {}
  };
}


export const saveProject = (project: Project, activeDocumentId?: string, updateDoc?: EmulatorDocument) => {
  if (updateDoc) {
    const documents = { ...project.documents };
    delete documents[updateDoc.id];
    project = { ...project, documents: { ...documents, [updateDoc.id]: updateDoc } };
  }

  const np = { ...project, activeDocumentId: activeDocumentId || project.activeDocumentId };
  saveToLocalStorage(np, `/projects/${project.name}`);

  return np;
};

export const saveDocument = (document: EmulatorDocument, project: Project) => {
  saveToLocalStorage(document, `/projects/${project.name}/${document.id}`);
}


export const deleteDocument = (document: EmulatorDocument, project: Project) => {
  removeFromLocalStorage(null, `/projects/${project.name}/${document.id}`);
}

export const openDocument = (id: string, project: Project) => {
  const doc = tryJSONParse(restoreFromLocalStorage(docKeyInProject(id, project.name))) //project.documents[id];
  return doc || {};
}


export const openProject = (name: string) => {
  const project = tryJSONParse(restoreFromLocalStorage(projectKey(name))) //project.documents[id];
  return project;
}


