import { AppLayout } from "../Components/AppLayout"

export const About = () => {
    return <AppLayout
        narrow={true}
        // sidebar={<Menu>
        //   <CommonMenu />
        // </Menu>}
        main={
            <div>
                <h2>Summary</h2>
                <p>
                    The Broadmind Velocity Mapping Tool helps Cloud engineers save implementation time when developing VTL templates using a
                    lightweight online editor with live preview and multiple documents management.
                </p>
                <h2>Features</h2>
                <p>
                    <ul>
                        <li>
                            Create valid and correct VTL templates in minutes rather than hours and do something worth your time instead
                        </li>
                        <li>
                            No server-side - rendering and persistance are happening right in your browser
                        </li>
                        <li>
                            Manage any number of documents
                        </li>
                        <li>
                            Keep your work and come back to it anytime
                        </li>
                        <li>
                            Export to JSON and/or paste directly into your AWS Console or Serverless / SAM application
                        </li>
                        <li>
                            Supports AWS Api Gateway <a
                                href="https://docs.aws.amazon.com/apigateway/latest/developerguide/api-gateway-mapping-template-reference.html"
                                target="_new"
                            >
                                $input and $util variables
                            </a>.
                        </li>
                    </ul>
                </p>
                <h2>Motivation</h2>
                <p>
                    If you ever had to create a template for a service or feature powered by <a href="https://velocity.apache.org">Apache Velocity Template Language</a>  you already
                    know why you are here. Nevertheless, next are some thoughts.<br />
                </p>
                <p>
                    Velocity Template Language is used in multiple spots that are invisible to users and even many developers,
                    however to the cloud architect or engineer working
                    on an <a href="https://docs.aws.amazon.com/apigateway/latest/developerguide/rest-api-data-transformations.html" target="_new">AWS API Gateway request/response mapping template</a>&nbsp;
                    or an <a href="https://docs.aws.amazon.com/appsync/latest/devguide/resolver-mapping-template-reference.html" target="_new">AppSync Resolver mapping template</a> VTL
                    is a powerful mapping tool that can provide multiple benefits. Examples include:
                </p>
                <p>
                    <ul>
                        <li>
                            input transformation to adapt a JSON request data for a SOA backend
                        </li>
                        <li>
                            output transformation to convert a JSON response into an XML format
                        </li>
                        <li>
                            server side rendering (SSR) directly from JSON responses
                        </li>
                    </ul>
                </p>
                <p>
                    On top of being independent of the upstream/downstream services, there is no lambda run cost charged for transformations performed this way, and
                    in some cases a VTL template may eliminate the need for lambda altogether.
                </p>
                <p>
                    While VTL is not complicated, the tooling is rather sparse and the usage is rather niche to the specific nuances of a few applications and
                    therefore caters to specific target audiences. To further complicate things, Velocity as a term is much more popular in the context of physics
                    and the search results in any major search engine reflect this.<br />
                    The native tooling in AWS only provides simple input boxes with the redeployment cycle too long to efficiently debug a template.
                </p>
                <p>
                    So there is not enough demand to invest in serious tooling, but every day somewhere in the world, in wfh, open space or even cubicle
                    environments, thousands of engineers are battling for their companies benefit and deliver VTL templates.
                </p>
                <p>
                    This service is intended to ease the work of and dedicated to these architects and engineers of the cloud frontier. Us.
                </p>
                <h2>About us</h2>
                <p>
                    Broadmind Cloud Technologies is a Cloud and Blockchain IT services provider working with clients across multiple
                    geographies and industries.
                    We are all about innovative solutions that benefit our customers.
                    Check out <a href="https://www.broadmind.eu" target="_new">broadmind.eu</a> for more details.
                </p>
                <h2>Contact</h2>
                <p>Yes we would like to hear from you. Get in touch over contact@broadmind.eu.</p>
            </div>

        }
    />

}
