import { Menu } from "antd";
import { Link } from "react-router-dom";

export const CommonMenu = () => <Menu mode="horizontal" style={{ minWidth: 0, flex: "auto" }}>
    <Menu.Item>
        <Link to="/">Home</Link>
    </Menu.Item>
    <Menu.Item>
        <Link to="/usage">Usage</Link>
    </Menu.Item>
    <Menu.Item>
        <Link to="/project/AWS Api Gateway">Examples</Link>
    </Menu.Item>
    <Menu.Item>
        <Link to="/about">About</Link>
    </Menu.Item>
</Menu>