export const tryJSONParse = (txt: any, defaultOnFail?: any) => {
    const dof = defaultOnFail !== undefined;
    try {
      if(!txt && dof)
        return defaultOnFail;
  
      if(typeof txt != 'string')
        return txt;
      return txt ? JSON.parse(txt) : {};
    } catch (e) {
      if(dof)
        return defaultOnFail;
      throw e;
    }
  }