import { Link } from "react-router-dom"
import { AppLayout } from "../Components/AppLayout"

export const Usage = () => {
    return <AppLayout
        narrow={true}
        // sidebar={<Menu>
        //   <CommonMenu />
        // </Menu>}
        main={
            <div>
                <h2>Definitions</h2>
                <p>
                    <ul>
                        <li>
                            <em>Velocity Template Language/VTL/Velocity</em> - a templating language created and maintained by
                            the <a href="https://velocity.apache.org/">Apache Velocity Project</a>.
                            Essentially a text template language, it has much use in multiple cloud infrastructure tools.
                        </li>
                        <li>
                            A <em>Template</em> in Velocity Mapping Tool is a text written in Apache Velocity Template Language.
                        </li>
                        <li>
                            A <em>Context</em> is a JSON object that will be fed into a VTL template to produce a preview.
                        </li>
                        <li>
                            A <em>Document</em> in the Broadmind Velocity Mapping Tool is a template and an input context.
                        </li>
                        <li>
                            A <em>Project</em> is a collection of documents.
                        </li>
                        <li>
                            A <em>read-only Project</em> is a project that is not updateable. All of it's documents are read-only as well.
                        </li>
                    </ul>
                </p>

                <h2>Working with templates</h2>
                <p>
                    <ol>
                        <li>
                            On the home page, click on "Start creating templates". You may also go directly to <Link to="/projects/default">your project</Link>.
                        </li>
                        <li>
                            Edit the template and the context in the tabs in the left part of the working area.
                        </li>
                        <li>
                            Watch the live preview change until the desired result is reached.
                        </li>
                        <li>
                            Copy the template code into your Serverless/SAM or a similar serverless application template.
                        </li>
                    </ol>
                </p>
                <h2>Exporting</h2>
                <p>
                    To export your whole project or the samples project,
                    Click the "Export Project" button and inspect/copy the printed object in your browser's developer console.
                </p>
                <h2>Autosave</h2>
                <p>
                    All the changes in your documents will be immediately persisted via your browser's local storage.
                </p>
                <h2>Security</h2>
                <p>
                    All your data is stored and processed locally via the browsers' localStorage API.
                    You might still get in trouble with your company security and/or compliance office, so watch what you paste here.
                </p>

            </div>

        }
    />

}
