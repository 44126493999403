import React, { useEffect } from 'react';
import './App.css';
import { DEFAULT_PROJECT_NAME, openProject, Project, saveDocument, saveProject } from './utils/domain-model';
import { defaultProject } from './samples/awsdocs';
import { ProjectView } from './Components/ProjectView';
import { Col, Row } from 'antd';
import {
  createBrowserRouter,
  RouterProvider,
  Link,
  useParams,
} from "react-router-dom";
import { AppLayout } from './Components/AppLayout';
import { About } from './Pages/About';
import { Usage } from './Pages/Usage';



const _storedProject: Project = openProject(DEFAULT_PROJECT_NAME);
// const storedProject = _storedProject || defaultProject;
if (!_storedProject) {
  saveProject(defaultProject)
  Object.values(defaultProject.documents).forEach((d) => saveDocument(d, defaultProject))
}

const projects: Record<string, Project> = {
  "AWS Api Gateway": defaultProject,
  "default": _storedProject || { name: "default", documents: {}, activeDocumentId: "" }
};


export const AppBody = () => {
  const { projectName: _pn } = useParams();
  const projectName = _pn || "default";

  const project = projects[projectName];

  return project ?
    <ProjectView projectName={projectName} /> :
    <div className="document-view">
      No such project,&nbsp;
      <Link to="/project/AWS Api Gateway">
        try some sample templates
      </Link>
    </div>
}

const HugeButton = ({ children, link }: { link: string, children: React.ReactElement }) => {
  return <Link to={link} className='huge-button'>
    {/* <Card> */}
    <div>{children}</div>
    {/* </Card>  */}
  </Link >
}

const actualCurrentHost = window.location.hostname;
const isLocalhost = actualCurrentHost === "localhost"

const replaceHost = (host?: string) => 
  `https://${host || window.location.host}${window.location.pathname}${window.location.search ? "?" + window.location.search : ""}`;

const Home = () => {
  useEffect(() => {
    if ((window.location.protocol !== "https:") && !isLocalhost)
      (window.location as any) = replaceHost();

  })

  return <>
    <AppLayout
      narrow={true}
      main={
        <div className='margin-horizontal-auto margin-horizontal-auto'>
          <div className='margin-horizontal-auto huge-buttons-grid-explainer'>
            <h2 className='text-center hero'>Broadmind Velocity Template Language Mapping Tool</h2>

            <p className='quote'>
              <q>
                The Velocity Template Language (VTL) is meant to provide the easiest, simplest, and cleanest way to incorporate dynamic content in a web page.
              </q> - <a href="https://velocity.apache.org/" target="_new">Apache Velocity Engine</a>
            </p>

            <p className='hero'>
              <span className='text-huge text-shadow'>
                Save hours of debugging and redeploying your serverless applications
                using a live coding environment with ready AWS examples.
              </span><br />
              {/* * Currently only API Gateway mappings. */}
            </p>
          </div>
          <Row align="middle" justify='center' className='huge-buttons-grid '>

            <Col span={12}>
              <HugeButton link="/project/default">
                <>Create VTL templates</>
              </HugeButton>
            </Col>
            <Col span={12}>
              <HugeButton link="/project/AWS%20Api%20Gateway">
                <>Check live examples</>
                {/* <>Check AWS API Gateway mapping samples</> */}
              </HugeButton>
            </Col>
            <Col span={12}>
              <HugeButton link="/about">
                <>Usage</>
              </HugeButton>
            </Col>
            <Col span={12}>
              <HugeButton link="/about">
                <>About</>
              </HugeButton>
            </Col>

          </Row>

          {/* <p>
            Comments? Drop us a line.
          </p> */}
        </div>

      }
    />
  </>;
}



const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/usage",
    element: <Usage />,
  },
  {
    path: "/project/:projectName/:documentId",
    element: <AppBody />,
  },
  {
    path: "/project/:projectName",
    element: <AppBody />,
  }
]);

export const App = () => {

  return <div>


    <RouterProvider router={router} />

  </div>

}