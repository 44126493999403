import { Link } from "react-router-dom";

import { tryJSONParse } from '../utils/json';
import { useEffect, useMemo, useState } from "react";
import { defaultNewDoc, EmulatorDocument, openDocument, Project, saveDocument } from "../utils/domain-model";
import { Form, Row, Col, Input, Tabs, Alert, Tooltip } from "antd";
import velocityjs from "velocityjs";
import TextArea from "antd/lib/input/TextArea";

import jsonpath from "jsonpath";

// const storedDocument: EmulatorDocument = tryJSONParse(restoreFromLocalStorage(DEFAULT_DOCUMENT_ID), null);

const injected = (template: any, context: any) => ({
    input: {
        path: (path: string) => {
            return (jsonpath.query(context, path) || [])[0];
        },
        json: (path: string) => {
            return JSON.stringify((jsonpath.query(context, path) || [])[0]);
        },
        params: () => {
            return context
        }
    },
    util: {
        escapeJavaScript: (a: string) => encodeURI(a),
        parseJson: (a: string) => JSON.parse(a),
        urlEncode: (a: string) => encodeURI(a),
        urlDecode: (a: string) => decodeURI(a),
        base64Encode: (a: string) => btoa(a),
        base64Decode: (a: string) => atob(a),
    }
});


const outputTabs = (output: string) => [
    {
        label: "Raw",
        children: <pre className='text-left'>{output}</pre>,
        key: "a"
    },
    {
        label: "HTML",
        children:
            // <iframe src={escape(output)} />,
            <div className='text-left' dangerouslySetInnerHTML={{ __html: output }} />,
        key: "b"
    }
];


const inputTabs = (contextIsValid: boolean) => [
    {
        label: "Template",
        children: <Form.Item name="template">
            <TextArea name="template" placeholder='template' style={{ height: "70vh" }} />
        </Form.Item>,
        key: "a"
    },
    {
        label: <>Context {(contextIsValid ? "" : <Tooltip title="Context contains invalid JSON, rendered as empty" placement="right">⚠</Tooltip>)}</>,
        children: <Form.Item name="context">
            <TextArea name="context" placeholder='context' style={{ height: "70vh" }} />
        </Form.Item>,
        key: "b"
    }
];

const renderVelocity = (template: string, context: any) => {
    try {
        return velocityjs.render(template || "", { ...injected(template, context), ...context });
    } catch (ex) {
        return "Velocity render failed with " + (ex as any).message + ", template:\n" + template
    }
}

export function DocumentView({ project, onChange }: { project: Project, onChange?: (document: EmulatorDocument) => void }) {
    // const [project, setProject] = useState<Project>(storedProject);
    const docId = project.activeDocumentId;
    const [doc, setDoc] = useState<EmulatorDocument>((() => {
        const candidate = (docId || project.activeDocumentId) && openDocument((docId || project.activeDocumentId), project);

        // storedDocument ||
        return candidate || defaultNewDoc()
    })()
    );

    useEffect(() => {
        setDoc(openDocument(docId, project) || defaultNewDoc(docId));
    }, [docId, project])

    const _ctx = useMemo(() => tryJSONParse(doc.context, new Error()), [doc.context]); 
    const ctx = useMemo(() => _ctx || {}, [_ctx])

    const contextIsValid = !(doc.context?.length && (_ctx instanceof Error));

    const rendered = useMemo(() => doc ? renderVelocity(doc?.template, ctx) : "", [doc, doc.context]);

    const onInputChange = (e: any) => {
        // doc[e.target.name as keyof EmulatorDocument] = e.target.value;
        const { name, value }: { name: keyof EmulatorDocument, value: string } = e.target;

        if ((doc[name]) === value) return;

        const nd = { ...doc, [name]: value };
        setDoc(nd);

        if (project.readOnly)
            return;

        saveDocument(nd, project);

        if (name === "name")
            onChange && onChange(nd);
    };


    useEffect(() => {
        doc.id && saveDocument(doc, project);
    }, [doc, project])

    const [form] = Form.useForm();

    useEffect(() => {
        form.setFieldsValue(doc)
        // saveProject(project);
    }, [doc, form])


    return (
        <div className="document-view">
            <Form
                form={form}
                onKeyUp={onInputChange}
                onBlur={onInputChange}
                onFinish={setDoc}
                initialValues={{ template: doc.template, context: doc.context, name: doc.name }}>

                {/* <Form.Item name="name"> */}
                <Row justify='start' align='top' className='text-left' gutter={0}>
                    <Col md={12} className="work-area">
                        <Form.Item name="name" className="no-margin-bottom">
                            <Input name="name" />

                        </Form.Item>
                        <small>
                                <Link to={`/project/${project.name}/${doc.id}`}>
                                    /{project.name}/{doc.id}
                                </Link>
                            </small>
                    </Col>
                    <Col md={10} className="work-area">
                        {
                            <Alert type='info' message={<div>{
                                project.readOnly ?
                                    <>
                                        You are in a read-only examples project. Your edits will not persist after reloading the page.
                                        To create and keep your own templates, <Link to="/project/default">switch to your own project</Link>.
                                    </>
                                    :
                                    <Link to="/project/AWS Api Gateway">
                                        Click here for sample templates
                                    </Link>
                            }
                            </div>
                            } />
                        }
                    </Col>
                </Row>
                {/* </ Form.Item> */}
                <Row gutter={0} justify="start">
                    <Col sm={24} md={12} className="work-area">
                        <Tabs items={inputTabs(contextIsValid)}></Tabs>
                        {/* <Button htmlType="submit">Update</Button> */}
                    </Col>

                    <Col sm={24} md={12} className="work-area">
                        <Tabs items={outputTabs(rendered)}></Tabs>
                    </Col>
                </Row>
            </Form>
        </div >
    );
}
