import { FileAddOutlined, DeleteOutlined } from "@ant-design/icons";
import { Button, Col, Menu, message, Modal, Row } from "antd";
import { mapValues } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { defaultProject } from "../samples/awsdocs";
import { defaultNewDoc, defaultNewProject, deleteDocument, EmulatorDocument, openDocument, openProject, Project, saveDocument, saveProject } from "../utils/domain-model"
import { CommonMenu } from "./CommonMenu";
import { DocumentView } from "./DocumentView";
import { AppLayout } from "./AppLayout";
import { useNavigate } from "react-router-dom";

// const sortedKeys = (obj?: any) => Object.keys(obj || {}).sort().reverse();
const sortedDocumentIdsByname = (project: Project) => Object.values(project.documents).sort((a, b) => a.name < b.name ? 1 : -1).map(v => v.id).reverse();

export const DocumentsList = ({ project, onDelete, mode }: { mode?: string, project: Project, onDelete: (docId: string) => void }) => {
    const keys = sortedDocumentIdsByname(project); // (a: string, b: string) => b.charCodeAt(0) - a.charCodeAt(0));

    return keys.length ?
        <Menu mode={mode || "vertical" as any} selectedKeys={[project.activeDocumentId]}>
            {keys.map((d: string) =>
                <Menu.Item key={d} title={d}>
                    <Row gutter={10} justify="space-between">
                        <Col span={22}>
                            <Link to={`/project/${project.name}/${project.documents[d].id}`}>
                                <div className="textOverflowEllipsis">
                                    {(project?.documents || {})[d]?.name || "no name"}
                                </div>
                            </Link>
                        </Col>
                        <Col span={2} hidden={project.readOnly} className="text-right">
                            <DeleteOutlined onClick={(e) => { e.stopPropagation(); onDelete(d) }} />
                        </Col>
                    </Row>
                </Menu.Item>
            )}
        </Menu>
        :
        <div className="work-area no-padding-right margin-bottom">
            No documents yet
        </div>;
}

export const ProjectView = ({ projectName }: { projectName: string }) => {
    const { documentId } = useParams();
    const navigate = useNavigate();

    // const project = useMemo(() => openProject(projectName), [projectName]);
    const [project, setProject] = useState<Project>((projectName && openProject(projectName)) || defaultProject);

    const [exporting, setExporting] = useState(false);

    const exportProject = () => {
        const p = {
            ...project,
            documents: mapValues(project.documents, (v, k) => openDocument(k, project))
        };
        // setExporting(true);
        message.success(`Project ${project.name} exported successfully, check your browser's console.`, 1000)
        console.log(p);
    };

    const reopenProject = useCallback((name: string) => {
        const np = openProject(name) || defaultNewProject();
        setProject(np);
    }, [setProject]);

    const newDoc = useCallback((project: Project) => {
        const nd = defaultNewDoc();
        saveDocument(nd, project);
        saveProject(project, nd.id, nd);
        // reopenProject(project.name);

        navigate(`/project/${project.name}/${nd.id}`);

        reopenProject(project.name);
    }, [navigate, reopenProject]);

    const _deleteDocument = (docId: string) => {
        const documents = { ...project.documents };
        const oldDoc = documents[docId];
        delete documents[docId];
        deleteDocument(oldDoc, project);

        const nextActive = sortedDocumentIdsByname(project)[0];

        const np = { ...project, nextActive, documents };

        saveProject(np);
        reopenProject(np.name);
    };

    const documentChanged = useCallback((document: EmulatorDocument) => {
        if (!document)
            return;

        saveProject(project, document.id, document);
        reopenProject(project.name);
    }, [project, reopenProject]);

    useEffect(() => {
        reopenProject(projectName);
        // navigate(`/project/${projectName}${documentId ? "/" + documentId : ""}`);
    }, [projectName, reopenProject]);

    useEffect(() => {
        const _docId =
            documentId ||
            project.activeDocumentId ||
            (project.documents[sortedDocumentIdsByname(project)[0]] || {}).id;

        if (!project.documents.length && !project.activeDocumentId) {
            newDoc(project);
            return;
        };

        const docId = project.documents[_docId] ? _docId : null;

        if (docId && (docId !== project.activeDocumentId)) {
            saveProject(project, docId);
            // };
            // reopenProject(projectName);
        }
        // if(!doc)
    }, [documentId, navigate, newDoc, project]); //[project.name, documentId, project, newDoc, reopenProject, projectName])

    // useEffect(() => {})
    // if (documentId !== project.activeDocumentId) {
    //     navigate(`/project/${project.name}/${docId}`)
    //     return;
    // }

    useEffect(() => {
        if (documentId && (documentId !== project.activeDocumentId)) {
            saveProject(project, documentId);
            reopenProject(project.name);
            //navigate(`/project/${project.name}/${project.activeDocumentId}`);
        }
    }, [documentId, navigate, project, reopenProject])


    if (!project.activeDocumentId)
        return <div className="work-area">Opening project...</div>;

    return <AppLayout Sidebar={({ mode }) =>
        <>
            <Row align="middle" justify="space-between">
                <Col span={16}>
                    <div className="work-area no-padding-right margin-bottom">
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            exportProject();
                        }}>Export project</Button>
                    </div>
                </Col>
                <Col span={4} hidden={project.readOnly} className="text-right">
                    <FileAddOutlined title="Add mapping to collection" onClick={() => newDoc(project)} style={{ cursor: "pointer", fontSize: 28, marginBottom: 20 }} />
                </Col>
            </Row>
            <DocumentsList mode={mode} project={project} onDelete={_deleteDocument} />
        </>
    }
        main={
            <>
                <DocumentView project={project} onChange={documentChanged} />
                <Modal
                    visible={exporting}
                    onOk={() => setExporting(false)}
                    onCancel={() => setExporting(false)}
                    maskClosable={true}
                    cancelButtonProps={{ style: { display: 'none' } }}>
                    <pre>{JSON.stringify(project, null, "  ")}</pre>
                </Modal>
            </>
        }
    />
}





    // useEffect(() => {
    //     documentChanged(activeDocument);
    // }, [activeDocument, documentChanged])

    // useEffect(() => {
    //     reopenProject(inP.name);
    // }, [inP.name, reopenProject])
   // useEffect(() => {
    //     // if (!documentId)
    //     //     return;

    //     const activeDocId = documentId || inP.activeDocumentId || "";

    //     if(!activeDocId)
    //         return;

    //     const activeDoc = inP.documents[activeDocId];

    //     if (!activeDoc) {
    //         const docId = sortedDocumentIdsByname(inP)[0];
    //         if (docId) {
    //             saveProject(inP, docId);
    //         }
    //         else {
    //             const nd = defaultNewDoc();
    //             saveDocument(nd, inP);
    //             saveProject(inP, nd.id, nd);
    //         }
    //     };

    //     reopenProject(inP.name);
    // }, [inP.name, documentId]);
    // ><div>
    //         <Row className='margin-horizontal-auto'>
    //             <Col span={4}>
    //                 {/* <Menu.Item onSelectCapture={e => e.stopPropagation()}> */}
    //                 <Row align="middle" justify="space-between" className="work-area no-padding-right">
    //                     <Col span={16}>
    //                         <Button onClick={(e) => { e.stopPropagation(); exportProject(); }}>Export project</Button>
    //                     </Col>
    //                     <Col span={4} hidden={project.readOnly} className="text-right">
    //                         <FileAddOutlined title="Add mapping to collection" onClick={newDoc} style={{ cursor: "pointer", fontSize: 30 }} />
    //                     </Col>
    //                 </Row>
    //                 <DocumentsList project={project} onChange={() => reopenProject(project.name)} onDelete={_deleteDocument} />
    //                 {/* </Menu.Item> */}
    //             </Col>
    //             <Col span={20}>
    //             </Col>
    //         </Row>
    //     </div>
