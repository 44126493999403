export const defaultProject =
{
    "name": "AWS Api Gateway",
    "readOnly": true,
    "documents": {
        "5fec77ff-3d6d-4cb5-b5ed-00d761dd3a5b": {
            "id": "5fec77ff-3d6d-4cb5-b5ed-00d761dd3a5b",
            "created": "2022-11-09T17:44:18.650Z",
            "updated": "2022-11-09T17:44:18.650Z",
            "name": "$input.params() (AWS API Gateway)",
            "template": "$util.base64Decode($util.base64Encode(\"base64 encode/decode test ok\"))\n\n#set($allParams = $input.params())\n{\n  \"params\" : {\n    #foreach($type in $allParams.keySet())\n    #set($params = $allParams.get($type))\n    \"$type\" : {\n      #foreach($paramName in $params.keySet())\n      \"$paramName\" : \"$util.escapeJavaScript($params.get($paramName))\"\n      #if($foreach.hasNext),#end\n      #end\n    }\n    #if($foreach.hasNext),#end\n    #end\n  }\n}",
            "context": `{"count":{"a":"''\\"/\\\\  eee e"},"items":{"b":22},"version":{"c":1}}`
        },
        "c6e58520-6851-4fd7-aee2-e96255ac220c": {
            "template": "#set($inputRoot = $input.path('$'))\n{\n  \"QueryResponse\": {\n    \"maxResults\": \"$inputRoot.QueryResponse.maxResults\",\n    \"startPosition\": \"$inputRoot.QueryResponse.startPosition\",\n    \"Employee\": {\n      \"Organization\": \"$inputRoot.QueryResponse.Employee.Organization\",\n      \"Title\": \"$inputRoot.QueryResponse.Employee.Title\",\n      \"GivenName\": \"$inputRoot.QueryResponse.Employee.GivenName\",\n      \"MiddleName\": \"$inputRoot.QueryResponse.Employee.MiddleName\",\n      \"FamilyName\": \"$inputRoot.QueryResponse.Employee.FamilyName\",\n      \"DisplayName\": \"$inputRoot.QueryResponse.Employee.DisplayName\",\n      \"PrintOnCheckName\": \"$inputRoot.QueryResponse.Employee.PrintOnCheckName\",\n      \"Active\": \"$inputRoot.QueryResponse.Employee.Active\",\n      \"PrimaryPhone\": { \"FreeFormNumber\": \"$inputRoot.QueryResponse.Employee.PrimaryPhone.FreeFormNumber\" },\n      \"PrimaryEmailAddr\": { \"Address\": \"$inputRoot.QueryResponse.Employee.PrimaryEmailAddr.Address\" },\n      \"EmployeeType\": \"$inputRoot.QueryResponse.Employee.EmployeeType\",\n      \"status\": \"$inputRoot.QueryResponse.Employee.status\",\n      \"Id\": \"$inputRoot.QueryResponse.Employee.Id\",\n      \"SyncToken\": \"$inputRoot.QueryResponse.Employee.SyncToken\",\n      \"MetaData\": {\n        \"CreateTime\": \"$inputRoot.QueryResponse.Employee.MetaData.CreateTime\",\n        \"LastUpdatedTime\": \"$inputRoot.QueryResponse.Employee.MetaData.LastUpdatedTime\"\n      },\n      \"PrimaryAddr\" : {\n        \"Line1\": \"$inputRoot.QueryResponse.Employee.PrimaryAddr.Line1\",\n        \"City\": \"$inputRoot.QueryResponse.Employee.PrimaryAddr.City\",\n        \"CountrySubDivisionCode\": \"$inputRoot.QueryResponse.Employee.PrimaryAddr.CountrySubDivisionCode\",\n        \"PostalCode\": \"$inputRoot.QueryResponse.Employee.PrimaryAddr.PostalCode\"\n      }\n    }\n  },\n  \"time\": \"$inputRoot.time\"\n}\n",
            "context": "{\n  \"QueryResponse\": {\n    \"maxResults\": \"1\",\n    \"startPosition\": \"1\",\n    \"Employee\": {\n      \"Organization\": \"false\",\n      \"Title\": \"Mrs.\",\n      \"GivenName\": \"Jane\",\n      \"MiddleName\": \"Lane\",\n      \"FamilyName\": \"Doe\",\n      \"DisplayName\": \"Jane Lane Doe\",\n      \"PrintOnCheckName\": \"Jane Lane Doe\",\n      \"Active\": \"true\",\n      \"PrimaryPhone\": { \"FreeFormNumber\": \"505.555.9999\" },\n      \"PrimaryEmailAddr\": { \"Address\": \"janedoe@example.com\" },\n      \"EmployeeType\": \"Regular\",\n      \"status\": \"Synchronized\",\n      \"Id\": \"ABC123\",\n      \"SyncToken\": \"1\",\n      \"MetaData\": {\n        \"CreateTime\": \"2015-04-26T19:45:03Z\",\n        \"LastUpdatedTime\": \"2015-04-27T21:48:23Z\"\n      },\n      \"PrimaryAddr\": {\n        \"Line1\": \"123 Any Street\",\n        \"City\": \"Any City\",\n        \"CountrySubDivisionCode\": \"WA\",\n        \"PostalCode\": \"01234\"\n      }\n    }\n  },\n  \"time\": \"2015-04-27T22:12:32.012Z\"\n}",
            "id": "c6e58520-6851-4fd7-aee2-e96255ac220c",
            "name": "Employee record example (API Gateway models and mapping templates)",
            "undefined": 0
        },
        "370a11e9-fbc6-4c46-b0f3-ac2b8c55f62b": {
            "id": "370a11e9-fbc6-4c46-b0f3-ac2b8c55f62b",
            "created": "2022-11-06T20:14:10.266Z",
            "updated": "2022-11-06T20:14:10.266Z",
            "name": "Sales invoice example (API Gateway models and mapping templates)",
            "template": "#set($inputRoot = $input.path('$'))\n{\n  \"DueDate\": \"$inputRoot.DueDate\",\n  \"Balance\": $inputRoot.Balance,\n  \"DocNumber\": \"$inputRoot.DocNumber\",\n  \"Status\": \"$inputRoot.Status\",\n  \"Line\": [\n#foreach($elem in $inputRoot.Line)\n    {\n      \"Description\": \"$elem.Description\",\n      \"Amount\": $elem.Amount,\n      \"DetailType\": \"$elem.DetailType\",\n      \"ExpenseDetail\": {\n        \"Customer\": {\n          \"value\": \"$elem.ExpenseDetail.Customer.value\",\n          \"name\": \"$elem.ExpenseDetail.Customer.name\"\n        },\n        \"Ref\": {\n          \"value\": \"$elem.ExpenseDetail.Ref.value\",\n          \"name\": \"$elem.ExpenseDetail.Ref.name\"\n        },\n        \"Account\": {\n          \"value\": \"$elem.ExpenseDetail.Account.value\",\n          \"name\": \"$elem.ExpenseDetail.Account.name\"\n        },\n        \"LineStatus\": \"$elem.ExpenseDetail.LineStatus\"\n      }\n    }#if($foreach.hasNext),#end\n        \n#end\n  ],\n  \"Vendor\": {\n    \"value\": \"$inputRoot.Vendor.value\",\n    \"name\": \"$inputRoot.Vendor.name\"\n  },\n  \"APRef\": {\n    \"value\": \"$inputRoot.APRef.value\",\n    \"name\": \"$inputRoot.APRef.name\"\n  },\n  \"TotalAmt\": $inputRoot.TotalAmt\n}",
            "context": "{\n  \"DueDate\": \"2013-02-1511\",\n  \"Balance\": 1990.19,\n  \"DocNumber\": \"SAMP001\",\n  \"Status\": \"Payable\",\n  \"Line\": [\n    {\n      \"Description\": \"Sample Expense\",\n      \"Amount\": 500,\n      \"DetailType\": \"ExpenseDetail\",\n      \"ExpenseDetail\": {\n        \"Customer\": {\n          \"value\": \"ABC123\",\n          \"name\": \"Sample Customer\"\n        },\n        \"Ref\": {\n          \"value\": \"DEF234\",\n          \"name\": \"Sample Construction\"\n        },\n        \"Account\": {\n          \"value\": \"EFG345\",\n          \"name\": \"Fuel\"\n        },\n        \"LineStatus\": \"Billable\"\n      }\n    }\n  ],\n  \"Vendor\": {\n    \"value\": \"GHI456\",\n    \"name\": \"Sample Bank\"\n  },\n  \"APRef\": {\n    \"value\": \"HIJ567\",\n    \"name\": \"Accounts Payable\"\n  },\n  \"TotalAmt\": 1990.19\n}",
            "undefined": 0
        },
        "a2817a2b-cd2c-4b7f-b408-58813f030933": {
            "id": "a2817a2b-cd2c-4b7f-b408-58813f030933",
            "created": "2022-11-06T20:18:25.038Z",
            "updated": "2022-11-06T20:18:25.038Z",
            "name": "News article example (API Gateway models and mapping templates)",
            "template": "#set($inputRoot = $input.path('$'))\n{\n  \"count\": $inputRoot.count,\n  \"items\": [\n#foreach($elem in $inputRoot.items)\n    {\n      \"last_updated_date\": \"$elem.last_updated_date\",\n      \"expire_date\": \"$elem.expire_date\",\n      \"author_first_name\": \"$elem.author_first_name\",\n      \"description\": \"$elem.description\",\n      \"creation_date\": \"$elem.creation_date\",\n      \"title\": \"$elem.title\",\n      \"allow_comment\": \"$elem.allow_comment\",\n      \"author\": {\n        \"last_name\": \"$elem.author.last_name\",\n        \"email\": \"$elem.author.email\",\n        \"first_name\": \"$elem.author.first_name\"\n      },\n      \"body\": \"$elem.body\",\n      \"publish_date\": \"$elem.publish_date\",\n      \"version\": \"$elem.version\",\n      \"author_last_name\": \"$elem.author_last_name\",\n      \"parent_id\": $elem.parent_id,\n      \"article_url\": \"$elem.article_url\"\n    }#if($foreach.hasNext),#end\n\n#end\n  ],\n  \"version\": $inputRoot.version\n}\n",
            "context": "{\n  \"count\": 1,\n  \"items\": [\n    {\n      \"last_updated_date\": \"2015-04-24\",\n      \"expire_date\": \"2016-04-25\",\n      \"author_first_name\": \"John\",\n      \"description\": \"Sample Description\",\n      \"creation_date\": \"2015-04-20\",\n      \"title\": \"Sample Title\",\n      \"allow_comment\": \"1\",\n      \"author\": {\n        \"last_name\": \"Doe\",\n        \"email\": \"johndoe@example.com\",\n        \"first_name\": \"John\"\n      },\n      \"body\": \"Sample Body\",\n      \"publish_date\": \"2015-04-25\",\n      \"version\": \"1\",\n      \"author_last_name\": \"Doe\",\n      \"parent_id\": 2345678901,\n      \"article_url\": \"http://www.example.com/articles/3456789012\"\n    }\n  ],\n  \"version\": 1\n}",
            "": "",
            "undefined": 0
        },
        "1b5f832e-ae4f-433d-bda9-9dcbfff2c486": {
            "id": "1b5f832e-ae4f-433d-bda9-9dcbfff2c486",
            "created": "2022-11-06T20:19:20.661Z",
            "updated": "2022-11-06T20:19:20.661Z",
            "name": "Photos example (API Gateway models and mapping templates)",
            "template": "#set($inputRoot = $input.path('$'))\n{\n  \"photos\": {\n    \"page\": $inputRoot.photos.page,\n    \"pages\": \"$inputRoot.photos.pages\",\n    \"perpage\": $inputRoot.photos.perpage,\n    \"total\": \"$inputRoot.photos.total\",\n    \"photo\": [\n#foreach($elem in $inputRoot.photos.photo)\n      {\n        \"id\": \"$elem.id\",\n        \"owner\": \"$elem.owner\",\n        \"secret\": \"$elem.secret\",\n        \"server\": \"$elem.server\",\n        \"farm\": $elem.farm,\n        \"title\": \"$elem.title\",\n        \"ispublic\": $elem.ispublic,\n        \"isfriend\": $elem.isfriend,\n        \"isfamily\": $elem.isfamily\n      }#if($foreach.hasNext),#end\n\t\t\t\n#end\n    ]\n  }\n}",
            "context": "{\n  \"photos\": {\n    \"page\": 1,\n    \"pages\": \"1234\",\n    \"perpage\": 100,\n    \"total\": \"123398\",\n    \"photo\": [\n      {\n        \"id\": \"12345678901\",\n        \"owner\": \"23456789@A12\",\n        \"secret\": \"abc123d456\",\n        \"server\": \"1234\",\n        \"farm\": 1,\n        \"title\": \"Sample photo 1\",\n        \"ispublic\": 1,\n        \"isfriend\": 0,\n        \"isfamily\": 0\n      },\n      {\n        \"id\": \"23456789012\",\n        \"owner\": \"34567890@B23\",\n        \"secret\": \"bcd234e567\",\n        \"server\": \"2345\",\n        \"farm\": 2,\n        \"title\": \"Sample photo 2\",\n        \"ispublic\": 1,\n        \"isfriend\": 0,\n        \"isfamily\": 0\n      }\n    ]\n  }\n}\n"
        }
    },
    "activeDocumentId": "a2817a2b-cd2c-4b7f-b408-58813f030933"
}