import { MenuOutlined } from "@ant-design/icons";
import { Image, Row, Col, Dropdown } from "antd";
import { Footer } from "antd/lib/layout/layout";
import React from "react";
import { Link } from "react-router-dom";
import { CommonMenu } from "./CommonMenu";

export const AppLayout = ({ Sidebar, main, narrow }: {
    Sidebar?: ({ mode }: { mode?: string }) => JSX.Element,
    narrow?: boolean,
    main: React.ReactElement
}) => {
    return <>
        <Row justify='space-between'>
            <Col  xs={18} sm={18} md={16} lg={18}>
                <Link to="/"><h1 className="work-area">VTL Mapping Tool</h1></Link>
            </Col>
            <Col xs={3} sm={2} md={0} style={{ paddingTop: 12 }} className="heading text-right">
                {Sidebar && <Dropdown overlay={
                    <div style={{ backgroundColor: "white", padding: 32, border: "1px solid #f0f0f0", boxShadow: "2px 2px 6px lightgrey", marginLeft: 3 }}>
                        <Sidebar mode="vertical" />
                    </div>}>
                    <MenuOutlined style={{ cursor: "pointer" }}/>
                </Dropdown>}

            </Col>
            <Col xs={3} sm={2} md={8} lg={6} className="heading text-right">
                <CommonMenu />
            </Col>
        </Row>
        <Row justify="center" className={`text-center margin-horizontal-auto content-main`}>
            {Sidebar && <Col xs={0} sm={0} md={4} lg={4}>
                {/* <Menu.Item onSelectCapture={e => e.stopPropagation()}> */}
                <Sidebar />
                {/* </Menu.Item> */}
            </Col>}
            <Col sm={24} md={20} lg={20}>
                <div className={`margin-horizontal-auto ${narrow ? "content-narrow" : ""}`}>
                    {main}
                </div>
            </Col>
        </Row>
        <Footer>
            <>
                &copy; 2019-{new Date().getUTCFullYear()} <Image 
                    src="/broadmind-logo-only.png" 
                    style={{ fontSize: 14, marginBottom: 2}} 
                    height="1em" 
                    alt="Broadmind Cloud Technologies Logo" />
            <a href="https://www.broadmind.eu" target="_new">Broadmind Cloud Technologies</a></></Footer>
    </>
}