import { isEqual } from "lodash";

const getLocalStorageKey = (key?: string) => "/veb" + (key || window.location.pathname);

export const saveToLocalStorage = (val: any, key?: string) => {
    if (typeof val !== "string")
        val = JSON.stringify(val);

    localStorage.setItem(getLocalStorageKey(key), val);

    return val;
}

export const removeFromLocalStorage = (val: any, key: string) => {
    localStorage.removeItem(getLocalStorageKey(key));
}
export const restoreFromLocalStorage = (key: string) => {
    return localStorage.getItem(getLocalStorageKey(key));
}

export const couldRestoreFromLocalStorage = (src: any, key?: string) => {
    const stored = key && restoreFromLocalStorage(key);

    const isStr = typeof src === "string";

    const strsrc = isStr ? src : JSON.stringify(src);
    try {
        const parsedStored = isStr || !stored ? stored : JSON.parse(stored);

        if (!stored || (isEqual(parsedStored, src)))
            return;

        // const shouldRestore = confirm("Found unsaved changes. Restore?")
        return parsedStored;

    } catch (ex) {
        return src
    }
}

export const maybeRestoreFromLocalstorage = (src: any, key?: string, confirm?: true | ((question: string) => boolean)) => {
    const could = couldRestoreFromLocalStorage(src, key);

    const shouldRestore = could && confirm && ((confirm === true) || confirm("Found unsaved changes. Restore?"));
    return shouldRestore || src;
}